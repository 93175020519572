<template>
  <div>
    <div class="text-2xl font-bold capitalize">
      {{ LL.login() }}
    </div>
    <UButton
      size="lg"
      color="neutral"
      block
      variant="subtle"
      class="capitalize mt-4"
      icon="i-simple-icons-google"
      :to="`${baseURL}/api/auth/google`"
      @click="handleSetRedirectLink"
      >Google
    </UButton>
    <UButton
      v-if="false"
      size="lg"
      color="neutral"
      block
      variant="subtle"
      class="capitalize mt-4"
      icon="i-simple-icons-facebook"
      :to="`${baseURL}/api/auth/facebook`"
      @click="handleSetRedirectLink"
      >Facebook
    </UButton>
    <UButton
      size="lg"
      color="neutral"
      block
      variant="subtle"
      class="capitalize mt-4"
      icon="i-simple-icons-github"
      :to="`${baseURL}/api/auth/github`"
      @click="handleSetRedirectLink"
      >Github
    </UButton>
    <USeparator :label="LL.or()" class="mt-4" />
    <UForm
      ref="loginFormRef"
      :schema="schemaLogin"
      :state="loginForm"
      class="w-full"
      :validate-on="['change']"
      @submit.prevent="submitForm"
    >
      <UFormField :label="LL.email_or_username()" name="email" class="mt-4">
        <UInput v-model="loginForm.email" placeholder="Email" size="lg" />
      </UFormField>
      <UFormField
        class="mt-4"
        :label="LL.password()"
        name="password"
        @keyup="handleKeyUp"
      >
        <UInput
          v-model="loginForm.password"
          size="lg"
          :placeholder="LL.password()"
          :type="passwordFieldType"
          :ui="{
            icon: {
              trailing: {
                pointer: 'pointer-events-auto',
              },
            },
          }"
        >
          <template #trailing>
            <UIcon
              class="cursor-pointer"
              :name="passwordVisibilityIconName"
              @click="togglePasswordVisibility"
            />
          </template>
        </UInput>
      </UFormField>
      <UAlert
        v-if="errorMessage"
        color="error"
        variant="subtle"
        class="mt-3"
        :title="errorMessage"
      />
      <UButton
        :disabled="!formValid || isLoading"
        :loading="isLoading"
        size="lg"
        block
        class="capitalize mt-4"
        @click.prevent="submitForm()"
        >{{ LL.login() }}
      </UButton>
      <div class="text-sm mt-2">
        {{ LL.do_you_have_account() }}
        <UButton variant="link" padded size="lg" to="/dang-ky">
          {{ LL.register() }}
        </UButton>
      </div>
    </UForm>
  </div>
</template>
<script setup lang="ts">
import { z } from "zod";
import { useAuthStore } from "~/features/auth";
import { typesafeI18n } from "~/locales/i18n-vue";
import { useUserStore } from "~/store";

const props = defineProps<{
  onLoggedInSuccess: () => void;
  redirectAfterLoginSuccess?: boolean;
}>();

const { LL } = typesafeI18n();

const userStore = useUserStore();
const toast = useToast();
const { path } = useRoute();
const MAX_AGE_REDIRECT_LINK_LOGIN = 90000; // 15 minutes after

const linkRedirectAfterLoginSuccess = useCookie("REDIRECT_LINK_AFTER_LOGIN_SUCCESS", {
  sameSite: "strict",
  maxAge: MAX_AGE_REDIRECT_LINK_LOGIN,
});

const authStore = useAuthStore();
const runtimeConfig = useRuntimeConfig();
const baseURL = runtimeConfig.public.apiBase;

const loginFormRef = ref();

const isLoading = ref(false);
const errorMessage = ref("");

const loginForm = reactive({
  email: "",
  password: "",
});

const schemaLogin = z.object({
  email: z.string().refine((value) => {
    return validateUsernameOrEmail(value);
  }, LL.value.input_your_email()),
  password: z.string().trim().min(8, LL.value.input_password_please()),
});

const formValid = computed(() => {
  try {
    schemaLogin.parse(loginForm);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
});

const passwordFieldType = ref("password");

const togglePasswordVisibility = () => {
  passwordFieldType.value = passwordFieldType.value === "password" ? "text" : "password";
};

const handleSetRedirectLink = () => {
  const { redirectAfterLoginSuccess } = props;
  linkRedirectAfterLoginSuccess.value = redirectAfterLoginSuccess ? "/" : path;
};

const passwordVisibilityIconName = computed(() => {
  if (passwordFieldType.value === "password") {
    return "i-heroicons-eye-slash-20-solid";
  }
  return "i-heroicons-eye-20-solid";
});

const submitForm = async () => {
  isLoading.value = true;
  await loginFormRef.value?.validate();
  const payload = {
    email: loginForm.email.trim(),
    password: loginForm.password.trim(),
  };
  userStore.login({
    payload,
    callbackError: (data) => {
      errorMessage.value = data ?? "";
      loginForm.password = "";
      isLoading.value = false;
    },
    callbackSuccess: () => {
      toast.add({
        title: LL.value.logged_in_successfully(),
      });
      authStore.setOpenAuthModal(false);
      loginForm.email = "";
      loginForm.password = "";
      isLoading.value = false;
      reloadNuxtApp();
    },
  });
};

function handleKeyUp(e) {
  if (e.code === EVENT_CODE_ENTER) {
    submitForm();
  }
}
</script>
